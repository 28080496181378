*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 80px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.header-btn {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 22px;
    background: white;
    font-weight: 500;
    color: rgb(252, 5, 5);
    font-family: math;
    cursor: pointer;
}


@media screen and (max-width: 360px) {
    .header-btn {
        margin-left: 25%;
        padding-top: 2px;
        padding-left: 10px;
        padding-bottom: 2px;
        padding-right: 10px;
        border-radius: 5px;
        font-size: 15px;
    }

    .logo {
        margin-left: -40%;
        height: 40px;
    }

}



@media screen and (min-width: 360px) and(max-width: 430px) {
    .logo {
        margin-left: -30%;
        height: 40px;
    }

    .header-btn {
        margin-left: 20px;
        padding-top: 2px;
        padding-left: 10px;
        padding-bottom: 2px;
        padding-right: 10px;
        
    }
}


