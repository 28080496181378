.bgimg {
    height: 650px;
    width: 100%;
}

.home-heading {
    font-family: "Rajdhani", sans-serif;
    text-align: center;
    margin-top: -400px;
    color: white;
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
}

.btn {
    margin-top: 30px;
    padding: 15px;
    margin-left: 670px;
    border: 1px solid gray;
    border-radius: 10px;
    background: white;
    font-size: 20px;
    font-weight: 700;
    color: red;
}

.btn:hover {
    background: red;
    color: white;
}

.category-section {
    padding-top: 80px;
}

.category-heading {
    text-align: center;
    font-size: 35px;
    color: #5877B8;
    font-weight: 800;
    font-family: math;
}

hr {
    margin-top: 0px;
    width: 8%;
    font-weight: bold;
    height: 2px;
    margin-left: 677px;
    background: #192f5d;
}


.category-img {
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 0px 0px 5px;
    padding-top: 40px;
    margin-top: 40px;
    margin-left: 13px;
    margin-right: 13px;
}

.category-img img {
    width: 100%;
    border-radius: 10px;
    margin-top: -40px;
}

.category-img p {
    padding-top: 15px;
    font-weight: 600;
    font-size: 40px;
    text-align: center
}

.img-btn {
    margin: 10px;
    padding: 7px;
    font-size: 18px;
    margin-left: 95px;
    border: 1px solid gray;
    border-radius: 15px;
    color: #5877B8;
    background: white;
    font-weight: 600;
    font-family: system-ui;
}



.properties-section {
    margin-bottom: 40px;
    margin-top: 300px;
    width: 99%;
}

.property-heading {
    font-weight: 700;
    text-align: center;
    font-size: 45px;
    color: red;
    font-family: monospace;
    text-transform: uppercase;
}

.property-box {
    display: flex;
    justify-content: center;
}

.property-img-box {
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 0px 5px;
    margin: 20px;
    border-radius: 9px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.property-img-box img {
    border-radius: 9px;
}

.property-img-box h2 {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
}

.property-img-box p{
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color: #606566;
}

.property-img-box h6 {
    LETTER-SPACING: 3PX;
    font-size: 20px;
    padding-bottom: 1rem !important;
}

.property-img-box h1 {
    font-size: 15px;
    padding-left: 10px;
}

.image-icon {
    padding-left: 80px;
    padding-top: 20px;
}

.btn1 {
    margin-left: 725px;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #de0e33;
    color: #de0e33;
    background: white;
    border-radius: 30px;
    font-weight: 500;
    box-shadow: 0px 0px 2px;
}

.card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    height: 25rem;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.cardimg {
    font-size: 41px;
    padding: 15px 0px;
}

.cardbody {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.card-heading{
    LETTER-SPACING: 3PX;
    font-size: 20px;
    padding-bottom: 20px;
}

.card-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #606566;
}

.top{
    margin-top: 40px;
}


.footer-content{
    color: white;
    margin-top: 25px;
}

.footer-logo{
    height: 60px;
    background: white;
}


a {
    text-decoration: none;
    list-style: none;
}

li {
    list-style: none;
}

#footer {
    padding-top: 50px;
    padding-bottom: 50px;
    background: black;
}

.footer_1 h2 {
    font-family: "Poppins", sans-serif !important;
    font-size: 25px;
    margin-top: 20px;
    color: white;
    font-weight: 400;
    padding-bottom: 15px;
    margin-left: 28px;
}

.footer_2 h2 {
    font-family: "Poppins", sans-serif !important;
    font-size: 25px;
    color: #f4f4f4;
    font-weight: 400;
    padding-bottom: 15px;
}

.footer_2 p {
    font-size: 20px;
    color: #ddd;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
}

.footer_1 li a {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    color: #c5c0c0f2;
    line-height: 28px;
}

.footer_1 li a:hover {
    color: #fff;
}

#footer ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
}

#footer ul.social-network li {
    display: inline;
    margin: 0 1px;
}


/* footer social icons */
#footer .social-network a.icoRss:hover {
    background-color: #F56505;
}

#footer .social-network a.icoFacebook:hover {
    background-color: #3B5998;
}

#footer .social-network a.icoTwitter:hover {
    background-color: #33ccff;
}

#footer .social-network a.icoGoogle:hover {
    background-color: #BD3518;
}

#footer .social-network a.icoVimeo:hover {
    background-color: #0590B8;
}

#footer .social-network a.icoLinkedin:hover {
    background-color: #007bb7;
}

#footer .social-network a.icoRss:hover i,
.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
#footer .social-network a.icoGoogle:hover i,
.social-network a.icoVimeo:hover i,
.social-network a.icoLinkedin:hover i {
    color: #fff;
}

#footer a.socialIcon:hover,
.socialHoverClass {
    color: #44BCDD;
}

#footer .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 5px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 45px;
    height: 45px;
    font-size: 20px;
    background-color: #053a75;
}

#footer .social-circle li i {
    margin: 0;
    line-height: 46px;
    text-align: center;
}

#footer .social-circle li a:hover i,
.triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

#footer .social-circle i {
    color: #fff;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

#footer .big-btn {
    padding: 10px 0px 11px 0px;
    width: 215px;
    margin-top: 5px;
}

#footer .big-btn i {
    font-size: 65px;
    margin-right: 0px !important;
}

#footer .big-btn strong {
    font-size: 25px;
    text-shadow: 1px 2px 0px #34659B;
    color: #fff;
    line-height: 24px;
}

#footer .big-btn .btn-text {
    margin-top: 6px;
}

#footer .big-btn small {
    font-size: 75%;
}

#footer .android-btn {

    width: 240px;
    padding: 5px 5px 5px 5px;
    background-color: #000;
    border-color: #000;

}

#footer .android-btn:hover {
    background-color: #272727;
}

#footer .android-btn i {
    font-size: 55px;
}

#footer .android-btn .btn-text {
    margin-top: 0px;
}

#footer .android-btn strong {
    font-size: 25px;
    text-shadow: none;
    color: #fff;
    line-height: 24px;
}

#footer .windows-btn {
    background-color: #008A00;
    width: 235px;
    padding: 5px 5px 5px 5px;
    border-color: #055805;
}

#footer .windows-btn:hover {
    background-color: #008A00;
}

#footer .windows-btn .btn-text {
    text-shadow: none;
    font-size: 26px;
    line-height: 25px;
}

/********************* footer_end ****************/



@media screen and (max-width: 360px) {
    .bgimg {
        height: 400px;
        width: 100%;
    }

    .home-heading {
        margin-top: -300px;
        font-size: 25px;
    }

    .btn {
        margin-top: 25px;
        padding: 10px;
        margin-left: 95px;
        font-size: 20px;
    }

    .properties-section {
        margin-top: 200px;
    }

    .property-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .property-img-box img {
        width: 319px;
    }

    .card{
        margin-left: 4%;
        margin-top: 30px;
    }
}

@media screen and (min-width: 360px) and(max-width: 450px) {
    .bgimg {
        height: 400px;
        width: 100%;
    }

    .home-heading {
        margin-top: -300px;
        font-size: 32px;
    }
button.btn {
    margin-left: 130px;
}
    .btn {
        margin-top: 25px;
        padding: 10px;
        margin-left: 140px;
        font-size: 20px;
    }

    .properties-section {
        margin-top: 150px;
    }

    .property-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .property-img-box img {
        width: 389px;
        height: 300px;
    }

    .property-img-box p {
        padding-left: 120px;
        font-size: 30px;
    }

    .img-btn {
        margin-left: 128px;
        font-size: 20px;
    }
}


.bgimg {
    width: 100%;
}