.form_fields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
}

.form-control {
    height: 50px !important;
    width: 50% !important;
}

.btn_submit{
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
    border: 1px solid gray;
    border-radius: 10px;
    background: white;
    font-size: 20px;
    font-weight: 700;
    color: red;
}