.heading {
    font-weight: 600;
    line-height: 1.25;
    color: rgb(34 34 34 / var(--tw-text-opacity));
}

.containerhead {

    font-size: 15px;
    font-weight: 400;
    line-height: 1.625;
    color: rgb(102 102 102 / var(--tw-text-opacity));
}